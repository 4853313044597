var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("main", { staticClass: "users-page" }, [
    _c("section", [
      _c("div", { staticClass: "navbar bg-white p-3" }, [
        _c(
          "div",
          { staticClass: "container text-center" },
          [
            _c("mega-input", {
              staticClass: "mb-0",
              attrs: {
                placeholder: _vm.$t("search_placeholder"),
                button: { icon: "i-search", click: _vm.onSearch }
              },
              on: { keyup: _vm.onKeyUp },
              model: {
                value: _vm.query,
                callback: function($$v) {
                  _vm.query = $$v
                },
                expression: "query"
              }
            })
          ],
          1
        )
      ])
    ]),
    _vm._v(" "),
    _c("section", [
      _c(
        "div",
        { staticClass: "container py-5" },
        [
          _c(
            "div",
            { staticClass: "row align-items-stretch" },
            _vm._l(_vm.list, function(user, i) {
              return _c(
                "div",
                {
                  key: i,
                  staticClass: "sm-down:col-12 md:col-6 lg:col-4 xl:col-3 mb-4"
                },
                [_c("user-card", { key: i, attrs: { data: user } })],
                1
              )
            }),
            0
          ),
          _vm._v(" "),
          _c("mega-loading", {
            attrs: { identifier: _vm.loadingId },
            on: { infinite: _vm.loadPage }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }