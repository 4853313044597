<template>
  <main class="users-page">
    <section>
      <div class="navbar bg-white p-3">
        <div class="container text-center">
          <mega-input
            class="mb-0"
            @keyup="onKeyUp"
            :placeholder="$t('search_placeholder')"
            v-model="query"
            :button="{ icon: 'i-search', click: onSearch }"
          />
        </div>
      </div>
      <!-- <div class="navbar bg-white p-3">
        <div class="container text-center">
          <router-link class="btn btn-sm btn-light mr-2" to="/users">{{
            $t("all_users")
          }}</router-link>
          <router-link
            @click="$root.list = []"
            class="btn btn-sm btn-light"
            to="/influencers"
            >{{ $t("influencers") }}</router-link
          >
        </div>
      </div> -->
    </section>

    <section>
      <div class="container py-5">
        <div class="row align-items-stretch">
          <div
            class="sm-down:col-12 md:col-6 lg:col-4 xl:col-3 mb-4"
            v-for="(user, i) in list"
            :key="i"
          >
            <user-card :data="user" :key="i" />
          </div>
        </div>

        <mega-loading
          :identifier="loadingId"
          @infinite="loadPage"
        ></mega-loading>
      </div>
    </section>
  </main>
</template>

<script>
import UserCard from "./components/user-card";

export default {
  name: "UsersPage",
  data() {
    return {
      query: "",
      loadingId: +new Date(),
      sexTypes: [{ value: 0, name: "All" }],
      filter: {
        search: "",
        sex: 0
      }
    };
  },
  computed: {
    list() {
      return this.$root.list;
    },
    loadIdentifier() {
      return this.$route.name || this.loadingId;
    }
  },
  watch: {
    filter: {
      handler() {
        this.$root.list = [];
        this.loadingId += 1;
      },
      deep: true
    },
    $route: {
      handler() {
        this.$root.list = [];
        console.log("route changed");
        this.loadingId += 1;
      },
      deep: true
    }
  },
  created() {
    if (!this.$root["history"].includes(this.$route.name))
      this.$root["list"] = [];
  },
  mounted() {
    this.$navbar.name = this.$t("users");
  },
  methods: {
    onKeyUp(event) {
      if (event.key === "Enter") this.onSearch();
    },

    onSearch() {
      this.filter.search = this.query;
    },

    loadPage($state) {
      this.loading = true;

      let params = {
        sn_id: this.$store.getters.selectedSnId,
        limit: 14,
        offset: this.list.length
      };

      if (this.filter.search) params.search = this.filter.search;

      if (this.$route.name === "Users") {
        this.$api.v1
          .get("/userList", { params })
          .then(({ data }) => {
            this.length = data.count;
            this.$navbar.name = this.$t("users") + " (" + this.length + ")";

            if (data.users.length) {
              setTimeout(() => {
                if ($state) $state.loaded();
              }, 500);
              this.$root.list.push(...data.users);
            } else $state.complete();
          })
          .catch(() => {
            this.$alert.danger("Oops.. Server error");
          });
      } else if (this.$route.name === "Influencers") {
        this.$api.v2base
          .get("/user/influencers", {
            params: {
              limit: 14,
              offset: this.list.length
            }
          })
          .then(res => {
            if (res.data.influencers.length) {
              $state.loaded();
              this.length = res.data.length;
              this.$root.list.push(...res.data.influencers);
            } else $state.complete();
          });
      }
    }
  },
  components: {
    UserCard
  }
};
</script>

<style lang="sass" scoped>
.router-link-exact-active
  background: #028cff
  color: rgba(255, 255, 255, 0.9)
</style>
